<template>
  <div class="container">
    <!-- <header class="jumbotron">
      <h3>{{content}}</h3>
    </header> -->
    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
    <button v-on:click="getPlanningFile" class="button button1">
      Télécharger le planning
    </button>
    <button v-on:click="refreshEvents">Actualiser...</button>
    <span style="color:red;">{{ message }}</span>
    <vue-cal
      class="vuecal--blue-theme"
      :time="true"
      :disable-views="['years', 'year']"
      :time-from="7 * 60"
      :time-to="22 * 60"
      :time-step="15"
      :hide-weekdays="[7]"
      :showWeekNumbers="false"
      :events="events"
      :on-event-click="onEventClick"
    >
      <template v-slot:event="{ event, view }">
        <div
          style="height: 100%;border: 1px solid gray;color: #000;"
          v-bind:style="{ background: event.color }"
        >
          <div class="vuecal__event-title" v-html="event.title" />
          <small class="vuecal__event-time">
            <span>{{ event.start.formatTime("HH:mm") }}</span> -
            <span>{{ event.end.formatTime("HH:mm") }}</span>
          </small>
          <div class="vuecal__event-content" v-html="event.content" />
        </div>
      </template>
    </vue-cal>

    <!-- Using Vuetify -->
    <!-- <div v-if="showDialog" role="document" class="v-dialog__content v-dialog__content--active" style="z-index: 202;" tabindex="0">
  <div class="v-dialog v-dialog--active" style="transform-origin: center center; max-width: 600px;">
    <div class="v-card v-sheet theme--light">
      <div class="v-card__title primary white--text py-2">
        <span class="headline text-uppercase">{{ selectedEvent.title }}</span>
        <div class="spacer"></div>
        <strong>{{ selectedEvent.start && selectedEvent.start.format('DD/MM/YYYY') }}</strong>
      </div>
      <div class="v-card__text py-4"><p v-html="selectedEvent.content"/>
        <strong>Event details:</strong>
        <ul>
          <li>Event starts at:  {{ selectedEvent.start && selectedEvent.start.formatTime() }}</li>
          <li>Event ends at: {{ selectedEvent.end && selectedEvent.end.formatTime() }}</li>
        </ul>
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
        </div>
    </div>
  </div>
</div> -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="showEvent"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {{ selectedEvent.title }}
              <div class="spacer"></div>
              <strong>{{
                selectedEvent.start && selectedEvent.start.format("DD/MM/YYYY")
              }}</strong>
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p v-html="selectedEvent.content" />
            <strong>Détails :</strong>
            <ul>
              <li>
                Début :
                {{ selectedEvent.start && selectedEvent.start.formatTime() }}
              </li>
              <li>
                Fin&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                {{ selectedEvent.end && selectedEvent.end.formatTime() }}
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../services/user.service";
import PointageService from "../services/pointage.service";

import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";

export default {
  name: "user",
  components: { VueCal },
  data() {
    return {
      loading: false,
      message: "",
      events: [],
      selectedEvent: {},
      showDialog: false,
    };
  },
  computed: {
    currentUserEvents() {
      return this.$store.state.user.events;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    if (this.currentUser.roles.includes("ROLE_EXTERNE")) {
      this.$router.push("/pointage");
    }
  },
  methods: {
    getPlanningFile: function() {
      this.loading = true;
      PointageService.getPlanningFile().then(
        (e) => {
          if (e.length) {
            e.forEach((element) => {
              // console.log(element.data);
              let bufferObj = Buffer.from(element.data, "base64");
              this.handleFileUpload(bufferObj, element.fichier);
            });
            this.message = "";
          } else {
            this.message = "Pas de fichier planning à télécharger";
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
    },
    handleFileUpload(file, filename) {
      var fileURL = window.URL.createObjectURL(new Blob([file]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", filename);
      document.body.appendChild(fileLink);

      fileLink.click();
    },
    refreshEvents: function() {
      this.loading = true;

      if (this.currentUserEvents) {
        this.events = this.currentUserEvents;
      }
      this.$store.dispatch("user/getEventData").then(
        (e) => {
          if (e.length) {
            this.events = e;
            this.message = "";
          } else {
            this.message = "Pas de résultat";
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );

      // UserService.getUserBoard().then(
      //   response => {
      //     this.events = response.data.events;
      //   },
      //   error => {
      //     this.content =
      //       (error.response && error.response.data) ||
      //       error.message ||
      //       error.toString();
      //     if (error.response.status == 401) {
      //       this.$store.dispatch('auth/logout');
      //       this.$router.push('/login');
      //     }
      //   }
      // );
    },
    onEventClick(event, e) {
      $("#showEvent").modal({
        keyboard: true,
      });
      $("#showEvent").modal("show");
      this.selectedEvent = event;
      this.showDialog = true;

      // Prevent navigating to narrower view (default vue-cal behavior).
      e.stopPropagation();
    },
  },
  mounted() {
    this.refreshEvents();
  },
};
</script>

<style>
.vuecal__event.leisure {
  background-color: rgba(253, 156, 66, 0.9);
  border: 1px solid rgb(233, 136, 46);
  color: #fff;
}
.vuecal__event.health {
  background-color: rgba(164, 230, 210, 0.9);
  border: 1px solid rgb(144, 210, 190);
}
.vuecal__event.sport {
  background-color: rgba(255, 102, 102, 0.9);
  border: 1px solid rgb(235, 82, 82);
  color: #fff;
}

.vuecal__event {
  cursor: pointer;
}

.vuecal__event-title {
  font-size: 1.2em;
  font-weight: bold;
  margin: 4px 0 8px;
}

.vuecal__event-time {
  display: inline-block;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.vuecal__event-content {
  font-style: italic;
}

button,
select,
textarea {
  background-color: transparent;
  border-style: none;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

.modal-header {
  background-color: rgba(66, 163, 185, 0.8);
  color: #fff;
}

.button {
  background-color: #68b5c7; /* Green */
  border: none;
  color: white;
  padding: 2px 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.button1 {
  background-color: white;
  color: black;
  border: 2px solid #68b5c7;
}

.button1:hover {
  background-color: #68b5c7;
  color: white;
}
</style>
